import * as React from 'react';

import { Footer, GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';
//import { Intro, ReviewFooter, SignUpProvider, Step1, Step2, Step3, Step4, Stepper } from '../components/signup';
import { Container } from 'react-bootstrap';

const SignUpPage = () => {
  if (typeof location !== `undefined`) {
    const params = new URLSearchParams(location.search);
    const id = params.has('id') ? params.get('id') : null;

    if (id) {
      location.href = '/lower-your-property-taxes';
    }
  }

  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <NavMenu solidColor notFixed />
      <Container>
        <div style={{ fontSize: 44, marginTop: 100 }}>We are currently not accepting new enrollments via online signup.</div>
        <div style={{ fontSize: 44, marginTop: 20 }}>Please call our office at 214-960-5590 if you need assistance.</div>
      </Container>
      <Footer sticky />

      {/*<NavMenu notFixed solidColor />*/}
      {/*<div className="d-flex align-items-start flex-column">*/}
      {/*  <div className="mb-auto w-100">*/}
      {/*    <SignUpProvider>*/}
      {/*      <Stepper />*/}
      {/*      <Intro />*/}
      {/*      <Step1 />*/}
      {/*      <Step2 />*/}
      {/*      <Step3 />*/}
      {/*      <Step4 />*/}
      {/*      <ReviewFooter />*/}
      {/*    </SignUpProvider>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default SignUpPage;
